<table class="mt-25">
  <tr>
    <td>22nd & 23rd June 2024</td>
    <td>West Midlands</td>
  </tr>
  <tr>
    <td>30th June 2024</td>
    <td>West Midlands</td>
  </tr>
  <tr>
    <td>10th August 2024</td>
    <td>Manchester</td>
  </tr>
</table>
